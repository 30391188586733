import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#1A5FB2',
      light: '#3180DE',
      lighter: '#62A2EF',
      darker: '#195194',
    },
    secondary: {
      main: '#D93737',
    },
    neutral: {
      main: '#B4CAE4',
      light: '#C7D7EB',
      dark: '#818995',
      contrastText: '#2C3E54',
    },
    background: {
      default: '#EBF2FA',
      darker: '#F2F2F2',
    },
  },
 });

export default theme;