exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-produkty-silne-uwierzytelnienie-2-fa-fibertoken-js": () => import("./../../../src/pages/produkty/silne-uwierzytelnienie-2FA-fibertoken.js" /* webpackChunkName: "component---src-pages-produkty-silne-uwierzytelnienie-2-fa-fibertoken-js" */),
  "component---src-pages-produkty-system-aml-js": () => import("./../../../src/pages/produkty/system-aml.js" /* webpackChunkName: "component---src-pages-produkty-system-aml-js" */),
  "component---src-pages-produkty-weryfikacja-tozsamosci-klienta-fiberid-js": () => import("./../../../src/pages/produkty/weryfikacja-tozsamosci-klienta-fiberid.js" /* webpackChunkName: "component---src-pages-produkty-weryfikacja-tozsamosci-klienta-fiberid-js" */),
  "component---src-pages-produkty-zaplatomat-js": () => import("./../../../src/pages/produkty/zaplatomat.js" /* webpackChunkName: "component---src-pages-produkty-zaplatomat-js" */),
  "component---src-pages-regulaminy-js": () => import("./../../../src/pages/regulaminy.js" /* webpackChunkName: "component---src-pages-regulaminy-js" */)
}

