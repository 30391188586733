import React from "react"
import { GlobalStyles } from '@mui/material';

const globalStyles = {
  strong: { fontWeight: 500 },
};

const Wrapper = ({ element }) => (
  <>
    <GlobalStyles styles={globalStyles} />
    {element}
  </>
);

export default Wrapper;